import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';
import { Modal, Button } from 'react-bootstrap'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Form } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';

class ValidationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos: [],
            candidato_id: '',
            time:300,
            loading: false,
            justify: '',
            msgErro: '',
            extend_end:false
        }
        this.videoRef = React.createRef();

        // this.videoRef=null;
    }
    componentDidMount() {
        // this.get_processos();

    }

    componentWillUnmount() {
        // clearTimeout(this.logs_timeout);
    }

    componentDidUpdate(props){
        if(this.props.show_modal_request==false && props.show_modal_request!=this.props.show_modal_request){
            this.setState({
                justify: '',
            msgErro: '',
            extend_end:false,
            })
        }
        if(this.videoRef.current!=null && ((this.props.open_validation_modal==true && props.open_validation_modal==false) || this.props.sala?.alunos[this.props.validation_student_id]?.stream?.id!=this.stream_id)){
            this.stream_id=this.props.sala?.alunos[this.props.validation_student_id]?.stream?.id;
            this.videoRef.current.srcObject = this.props.sala?.alunos[this.props.validation_student_id]?.stream;

        }
        console.log('id stream',this.props.sala?.alunos[this.props.validation_student_id]?.stream?.id,props.sala?.alunos[this.props.validation_student_id]?.stream?.id)
    }

    convertMinute(value){
        let label = '';
        if(value<60){
            label='30 segundos';
        }
        else{
            label=(parseInt(value/60)+' minuto(s)') + (Number.isInteger(value/60)?'':' e 30 segundos');
        }
        return label;
    }

    salvar(approved){
       
        this.request_validation(approved,this.props.validation_student_id)

        
        
    }

    request_validation(approved,user_id) {
        let loadings_request_validations={...this.props.loadings_request_validations}
        loadings_request_validations[user_id]=true;
        this.props.setState({loadings_request_validations})
        this.setState({msgErro:''})
       
        fetch(`${URL}api/fiscal/request_validation`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                processo_id:this.props.processo.id,
                sala_id:this.props.sala.id,
                approved,
                user_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    let loadings_request_validations={...this.props.loadings_request_validations}
                    loadings_request_validations[user_id]=false;
                    this.props.setState({loadings_request_validations})
                    this.setState({msgErro:erro})
                    // this.props.mudarLoadingHome(false);
                    
                }
                else {
                    // this.setState({loa})
                    let loadings_request_validations={...this.props.loadings_request_validations}
                    loadings_request_validations[user_id]=false;
                    this.props.setState({loadings_request_validations,open_validation_modal:false})
                    this.props.onClose(this.props.validation_student_id);

                }
            } catch (err) {
                console.log(err);
                
            }

        })
            .catch((err) => {
                console.log(err);
        this.setState({loading_access:false});
                
            });
    }



    render() {
        // let sala_id = this.props.sala != '' ? this.props.sala.id : '';
        // let candidatos = this.props.candidatos.filter((item, id) => {
        //     if (item.sala_id != sala_id) {
        //         return false;
        //     }
        //     return true;
        // });
        return (
            <>
                {/* <Button variant="primary" onClick={() => this.setState({ show_modal_action: true,candidato_id:'',acao:'' })}>
                    Reportar Ação Suspeita
                </Button> */}


                {<Modal
                    show={this.props.open_validation_modal}
                    onHide={() => {
                        this.props.setState({ open_validation_modal: false })
                        this.props.onClose(this.props.validation_student_id);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span style={{color:'black'}}>Solicitação para entrar na avaliação</span>
                        </Modal.Title>
                    </Modal.Header>
                    {this.props.validation_student_id!=null &&  <>
                    <Modal.Body>
                        {this.state.msgErro != '' && <div>
                            <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        {this.props.sala?.alunos[this.props.validation_student_id]!=undefined && <div className="form-group row mb-0">
                            <div className="col-sm-12">
                                <p style={{color:'black',marginBottom:5,fontSize:15}}><b style={{marginRight: "2px"}}>Nome: </b>{this.props.sala?.alunos[this.props.validation_student_id].name}</p>
                                <p style={{color:'black',marginBottom:5,fontSize:15}}><b style={{marginRight: "2px"}}>Sala: </b> Sala {this.props.sala.numero}</p>
                                <div className='mt-2' style={{position:'relative',background: 'black'}}>
                                    <video  ref={this.videoRef} className="video_tag_aluno" autoPlay muted style={{ display: !this.props.number_online?.includes(this.props.validation_student_id.toString()) ? 'none' : 'flex', width: '100%',  borderRadius: 10,height:200 }} ></video>
                                    {!this.props.number_online.includes(this.props.validation_student_id.toString()) && <div className="desconectado{{$aluno->id}}"
                                                    style={{ height: '100%', width: '100%', background: 'black',height:200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <p style={{ color: 'white', fontSize: '14px' }}>Desconectado...</p>
                                                </div>}
                                </div>
                                <div className='d-flex justify-content-center mt-2'>

                                {this.props.processo.screen_sharing == true && <div
                                                        onClick={() => {
                                                            // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                            // this.fullscreen_r    ef.current.srcObject = aluno.videoRef.current.srcObject;
                                                            this.props.alter_video(this.props.sala?.alunos[this.props.validation_student_id])
                                                        }}
                                                        data-tip={"Alterar o vídeo do candidato para a webcam ou tela compartilhada"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        {this.props.loading_alter_video[this.props.validation_student_id] != true && <i style={{ color: 'inherit' }} className="mdi mdi-monitor-multiple"></i>}
                                                        {this.props.loading_alter_video[this.props.validation_student_id] == true && <div className="spinner-border text-primary" role="status" style={{ width: '1rem', height: '1rem', margin: 0 }} >
                                                        </div>}

                                                    </div>}
                                                    {this.props.processo.good_quality && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div onClick={() => {
                                                    this.props.good_quality_student_alter(this.props.sala?.alunos[this.props.validation_student_id])
                                                }} data-tip={"Video em boa qualidade"} className="icon_box" style={{ position: 'relative' }}>
                                                    <i style={{ color: 'inherit' }} className="mdi mdi-high-definition"></i>
                                                    <ReactTooltip />
                                                </div>
                                            </div>}
                                </div>

                            </div>
                        </div>}
                        
                       
                    </Modal.Body>
                    {this.props.loadings_request_validations[this.props.validation_student_id] != true && <Modal.Footer>
                        <Button variant="danger" className="btn-lg" onClick={() => this.salvar(false)}>Negar</Button>
                        <Button variant='success' className="btn-lg" onClick={() => this.salvar(true)}>Aprovar</Button>

                    </Modal.Footer>}
                    {this.props.loadings_request_validations[this.props.validation_student_id] == true && <Modal.Footer><div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div></Modal.Footer>}
                    </>}

                </Modal>}

            </>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(ValidationModal);
