
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto: '',
            chat_open: false,
            alunos: {},
            aluno_select: { mensagens: [] },
            comunicados: [],
            name_filter: ''
        };
        this.messages_view = React.createRef();
        this.clearTimeoutMovement = {}
    }



    componentDidMount() {
        console.log('mount')
        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        // let aluno_select={};
        // if(this.props.user.role=='fiscal_coordenador'){
        //     aluno_select =  { id: 'comunicado', name: 'Comunicado Geral' };
        // }
        // else{
        //     if(Object.values(this.props.alunos).length>0){
        //         aluno_select =  Object.values(this.props.alunos)[0];
        //     }
        // }
        // // this.props.mudarState({ alunos: this.props.alunos,  comunicados: this.props.comunicados });
        // this.setState({aluno_select});
        this.admin_id = this.props.admin_id;
        this.props.socket.on("send_msg_fiscal", function (data) {
            if (data.admin_id != this.admin_id) {
                let alunos = { ...this.props.alunos };

                if (alunos[data.aluno_id] != undefined) {

                    alunos[data.aluno_id].mensagens.push({
                        message: data.msg,
                        sala_id: data.sala_id,
                        sender: { id: data.aluno_id, name: data.nome_chat },
                        sender_id: data.aluno_id,
                        uuid: data.id_msg,
                        warning: false,
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss')

                    });
                    alunos[data.aluno_id].order = moment().unix();
                    // this.setState({ alunos });
                    // if (this.state.aluno_select.id == data.aluno_id) {


                    //     alunos[data.aluno_id].counter = 0;
                    //     if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {
                    //         setTimeout(() => {
                    //             this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                    //         }, 100);
                    //     }
                    //     this.props.mudarState({ alunos });
                    //     this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id });


                    // }
                    // else 
                    if (this.props.alunoChat_select.id == data.aluno_id && (this.props.chat_open == 0 || this.props.show_chat_meet==true)) {
                        alunos[data.aluno_id].counter = 0;
                        this.props.mudarState({ alunos });
                        this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id });
                    }

                    else if(data.from_admin!=true) {
                        alunos[data.aluno_id].counter += 1;
                        this.props.mudarState({ alunos });
                    }
                    else{
                        this.props.mudarState({ alunos });
                    }
                }

            }
        }.bind(this));

        this.props.socket.on("request_pause", function (data) {
            console.log('request pause', data);
            let alunos = { ...this.props.alunos };

            if (alunos[data.aluno_id] != undefined) {
                if (data.type == 'request') {

                    alunos[data.aluno_id].requests.push({
                        processo_id: this.props.processo.id,
                        user_id: data.aluno_id,
                        id: data.id,
                        reason: data.reason,
                        sala_id: this.props.sala.id,
                        approved: null,
                        started: null,
                        justify: null,
                        time: null,
                        extend_end: false,
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                        ...data
                    });
                    this.props.mudarState({ alunos });
                } else if (data.type == 'response' || data.type=='started' || data.type=='ended') {
                    let index_request = alunos[data.aluno_id].requests.findIndex((elemen)=>{
                        if(elemen.id==data.id){
                            return true;
                        }
                        return false;
                    })
                    alunos[data.aluno_id].requests[index_request] = data;
                    console.log('substituiu');
                    console.log(alunos[data.aluno_id].requests);

                    this.props.mudarState({ alunos });
                    this.props.clearTimeout(data.id);
                }

                // this.setState({ alunos });
            }

        }.bind(this));

        this.props.socket.on("send_warning_movement", function (data) {

            let video_blink_movement = { ...this.props.video_blink_movement }
            video_blink_movement[data.aluno_id] = true;
            this.props.mudarState({ video_blink_movement });
            clearTimeout(this.clearTimeoutMovement[data.aluno_id]);
            this.clearTimeoutMovement[data.aluno_id] = setTimeout(() => {
                if (this.props.video_blink_movement[data.aluno_id]) {
                    let video_blink_movement = { ...this.props.video_blink_movement }
                    video_blink_movement[data.aluno_id] = false;
                    this.props.mudarState({ video_blink_movement });
                }

            }, 10000);

        }.bind(this));


        this.props.socket.on("send_warning_behavior_fiscal", function (data) {
            if (data.admin_id != this.admin_id) {

                let alunos = { ...this.props.alunos };
                if (alunos[data.aluno_id] != undefined) {

                    alunos[data.aluno_id].mensagens.push({
                        message: data.msg,
                        sala_id: data.sala_id,
                        sender: { id: data.aluno_id, name: data.nome_chat },
                        sender_id: data.aluno_id,
                        uuid: data.id_msg,
                        warning: true,
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                    alunos[data.aluno_id].order = moment().unix();
                    let video_blink = { ...this.props.video_blink }
                    if (this.props.online.includes(data.aluno_id.toString())) {
                        video_blink[data.aluno_id] = data.type == 'in' ? false : true;
                    }
                    else {
                        video_blink[data.aluno_id] = false;
                    }

                    // $('#msg_count
                    // $('#msg_count' + data.aluno_id).addClass('msg_count_warning');
                    if (this.state.aluno_select.id == data.aluno_id) {
                        alunos[data.aluno_id].counter = 0;

                        if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {

                            setTimeout(() => {
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                            }, 200);
                        }
                        this.props.mudarState({ alunos, video_blink });
                        this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id, warning: true });


                    }
                    else if (this.props.alunoChat_select.id == data.aluno_id && this.props.chat_open == 0) {
                        alunos[data.aluno_id].counter = 0;
                        this.props.mudarState({ alunos, video_blink });
                        this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id, warning: true });
                    }
                    else {
                        // alunos[data.aluno_id].counter += 1;
                        this.props.mudarState({ alunos, video_blink });
                    }

                }
            }
        }.bind(this));


        this.props.socket.on("msg_receive", function (data) {
            // $('#' + data.id_msg).removeClass();
            // $('#' + data.id_msg).addClass('fas fa-check-double');
            let alunos = { ...this.props.alunos };
            if (alunos[data.aluno_id] != undefined) {

                for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
                    if(data.all==true && alunos[data.aluno_id].mensagens[i].sender.id==this.props.user.id){
                        alunos[data.aluno_id].mensagens[i].visto = true;
                    }
                    else if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
                        alunos[data.aluno_id].mensagens[i].visto = true;
                        break;
                    }
                }
                this.props.mudarState({ alunos });
            }
        }.bind(this));

        this.props.socket.on("msg_save" + this.admin_id, function (data) {
            // $('#' + data.id_msg).removeClass();
            let alunos = { ...this.props.alunos };
            if (alunos[data.aluno_id] != undefined) {

                for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
                    if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
                        alunos[data.aluno_id].mensagens[i].visto = false;
                        break;
                    }
                }
                this.props.mudarState({ alunos });
            }
            // $('#' + data.id_msg).addClass('fas fa-check');
        }.bind(this));


        this.props.socket.on("finish_event", function (data) {
            // $('#' + data.id_msg).removeClass();
            console.log(data);
            let alunos = { ...this.props.alunos };
            if (alunos[data.aluno_id] != undefined) {
                alunos[data.aluno_id].finish = 'F';
                this.props.mudarState({ alunos });
            }

        }.bind(this));


        this.props.socket.on("presence", function (data) {
            // $('#' + data.id_msg).removeClass();
            console.log(data);
            let alunos = { ...this.props.alunos };
            if (alunos[data.aluno_id] != undefined) {
                alunos[data.aluno_id].presence = data.presence;
                this.props.mudarState({ alunos });
            }

        }.bind(this));


        this.props.socket.on("request_validation", function (data) {
            // $('#' + data.id_msg).removeClass();
            console.log('request_validation',data);
            let alunos = { ...this.props.alunos };
            if (alunos[data.user_id] != undefined) {
                console.log('ira atualizar')
                if(data.type!='response'){
                    alunos[data.user_id].request_validation = data;
                }
                else {
                    alunos[data.user_id].request_validation = null;
                }
                this.props.mudarState({ alunos });
            }

        }.bind(this));



        this.props.socket.on("msg_receive_comunicado", function (data) {
            // $('#' + data.id_msg).removeClass();
            // $('#' + data.id_msg).addClass('fas fa-check-double');
            let comunicados = this.props.comunicados;
            console.log(comunicados)
            console.log(data)
            let breaked = false;
            for (let i = 0; i < comunicados.length; i++) {
                if (comunicados[i].id_msg == data.id_msg) {
                    comunicados[i].send = true;
                    breaked = true;
                    break;
                }
            }
            if (breaked == false) {
                comunicados.push({
                    message: data.msg,
                    sala_id: data.sala_id,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                });
            }
            this.props.mudarState({ comunicados });
        }.bind(this));
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/fiscal/receive_all_fiscal`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }


    componentDidUpdate(props) {
        if (props.show_modal_chat_geral == true && this.props.show_modal_chat_geral == false) {
            this.setState({ aluno_select: { mensagens: [] }, });
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    send_message() {
        let uid_msg = this.uuidv4();
        let msg = this.state.texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            this.props.socket.emit("send_msg", {processo_id:this.props.processo.id, msg: msg, sala_id: this.state.aluno_select.sala_id, aluno_id: this.state.aluno_select.id, nome_chat: this.props.user.name, id_msg: uid_msg, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, id_adm: this.props.user.id });
            // $(this).val('');
            // $('#body_msg').append(template_send.replace(/%MSG%/g,msg).replace(/%ID%/g,uid_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            let alunos = { ...this.props.alunos };
            // let aluno_select={...this.state.aluno_select};
            // aluno_select.mensagens.push({
            //     sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
            //     receive_id: this.state.aluno_select.id, sala_id:this.state.aluno_select.sala_id, uuid: uid_msg, visto: false, message: msg
            // });
            alunos[this.state.aluno_select.id].mensagens.push({
                sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
                receive_id: this.state.aluno_select.id, sala_id: this.state.aluno_select.sala_id, uuid: uid_msg, visto: undefined, message: msg,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')

            });
            this.setState({ texto: '' });
            this.props.mudarState({ alunos: alunos });
            setTimeout(() => {
                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
            }, 100);
        }
    }

    send_comunicado() {
        let msg = this.state.texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            let id_msg = this.uuidv4();

            this.props.socket.emit("comunicado_geral", { id_msg, nome_chat: 'Comunicado Geral', msg: this.state.texto, id_adm: this.props.user.id, faculdade_id: this.props.faculdade.id });
            let comunicados = [...this.props.comunicados];
            comunicados.push({
                message: msg,
                id_msg,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')

            });
            this.props.mudarState({ comunicados: comunicados });
            this.setState({ texto: '' });

        }
    }




    render() {
        let alunos = Object.values(this.props.alunos);
        alunos = alunos.sort(function (a, b) { return b.order - a.order })
        let online = this.props.online;
        return (
            true == false ? <div
                style={{
                    background: 'white', height: '600px', width: '100%',
                    bottom: this.state.chat_open == false ? '-400px' : 0,
                    right: '15px',
                    borderRadius: '5px',
                    wordBreak: 'break-word',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }} id="chat_father">


                <div className="row">
                    <div className="col-4" style={{ borderRight: '1px solid grey', paddingRight: 0, overflowY: 'scroll', height: '600px' }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ position: 'relative', padding: 10, height: 60 }}>
                                <div style={{ position: 'absolute', top: 21, left: 20 }}>
                                    <i className="fas fa-search" style={{ fontSize: '1rem' }}></i>
                                </div>
                                <input autoComplete="off" onChange={(e) => this.setState({ name_filter: e.target.value })} value={this.state.name_filter} name="msg" placeholder="Buscar candidato..." className="form-control msg_input" style={{ resize: 'none', height: '100%', borderRadius: 50, paddingLeft: 40 }} />
                            </div>

                            {this.props.user.role == 'fiscal_coordenador' && <div onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ aluno_select: { id: 'comunicado', name: 'Comunicado Geral' }, texto: '' });
                            }} style={{ padding: 10, borderBottom: '1px solid rgba(0,0,0,.1)', cursor: 'pointer', background: '#38a4f8', borderTopLeftRadius: '5px' }}>
                                <h6 style={{ color: 'black' }}>Comunicado geral</h6>
                                {this.props.comunicados.length > 0 && <div>
                                    <span style={{ color: 'white' }}>{this.props.comunicados[this.props.comunicados.length - 1].message}</span>
                                </div>}
                            </div>}
                            {alunos.map((aluno, id2) => (
                                <div hidden={!aluno.name.toLowerCase().includes(this.state.name_filter.toLowerCase()) && !aluno.sala.toLowerCase().includes(this.state.name_filter.toLowerCase()) && this.state.name_filter != ''} onClick={(e) => {
                                    e.stopPropagation();

                                    if (aluno.mensagens.length > 0) {
                                        this.props.socket.emit('msg_receive_aluno', { sala_id: aluno.sala_id, aluno_id: aluno.id, uuid: aluno.mensagens[aluno.mensagens.length - 1].uuid, faculdade_id: this.props.faculdade.id });
                                        this.receive_all(aluno.mensagens[aluno.mensagens.length - 1].uuid, aluno.sala_id, aluno.id);
                                    }
                                    let alunos = { ...this.props.alunos };
                                    alunos[aluno.id].counter = 0;
                                    this.props.mudarState({ alunos });
                                    this.setState({ aluno_select: { ...aluno, indice: id2 }, texto: '' }, () => {
                                        console.log('oi');
                                    });
                                    setTimeout(() => {
                                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                    }, 100);
                                }} key={id2} style={{ display: 'flex', justifyContent: 'space-between', padding: 10, borderBottom: '1px solid rgba(0,0,0,.1)', cursor: 'pointer', background: this.state.aluno_select.id == aluno.id ? '#f1f1f1' : 'white', borderTopLeftRadius: 0 }}>
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h6 style={{ color: 'black' }}>{aluno.name} - {aluno.sala}</h6>
                                            <div></div>
                                        </div>
                                        {aluno.mensagens.length > 0 && <div>
                                            <span style={aluno.counter > 0 ? { fontWeight: 'bold', color: 'black' } : {}}>{aluno.mensagens[aluno.mensagens.length - 1].message}</span>
                                        </div>}
                                    </div>

                                    <div style={{ alignItems: 'flex-end', display: 'inherit', position: 'relative' }}>
                                        {aluno.counter > 0 && <div style={{
                                            padding: '8px',
                                            paddingTop: '3px',
                                            background: 'rgb(75, 181, 67)',
                                            borderRadius: '50px',
                                            position: 'absolute',
                                            top: 0,
                                            textAlign: 'center',
                                            paddingBottom: '3px',
                                            right: '10px'
                                        }}>
                                            <span style={{ color: 'white', textAlign: 'center' }}>{aluno.counter}</span>
                                        </div>}

                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            <div>
                                                {/* {aluno.mensagens.length > 0 && moment(aluno.mensagens[aluno.mensagens.length - 1].created_at).format('DDMMYYYY') != moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(aluno.mensagens[aluno.mensagens.length - 1].created_at).format('DD/MM/YYYY')}</p>}
                                                {aluno.mensagens.length > 0 && moment(aluno.mensagens[aluno.mensagens.length - 1].created_at).format('DDMMYYYY') == moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(aluno.mensagens[aluno.mensagens.length - 1].created_at).format('HH:mm')}</p>}
                                                {aluno.mensagens.length == 0 && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment().format('HH:mm')}</p>} */}
                                                {online.includes(aluno.id.toString()) && <div style={{ padding: 5, width: 55, height: 31, }}>
                                                    <span style={{ color: '#4BB543', textAlign: 'center' }}>Online</span>
                                                </div>}
                                                {!online.includes(aluno.id.toString()) && <div style={{ padding: 5, width: 55, height: 31, }}>
                                                    <span style={{ color: 'indianred', textAlign: 'center' }}>Offline</span>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-8" style={{ paddingLeft: 0 }}>
                        <a id="close_chat"

                            style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', backgroundColor: this.state.aluno_select.id != undefined ? '#f2f2f2' : 'white', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

                            <p style={{ margin: 0, color: 'black', textAlign: 'center' }}>{this.state.aluno_select.name}</p>

                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                                position: 'absolute',
                                right: '10px',
                                // color: 'white',
                                fontSize: '2rem'
                            }}
                                onClick={() => {
                                    this.props.closeChat();
                                }}
                            >
                                <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                            </button>

                        </a>
                        <div ref={this.messages_view} style={{ height: '490px', padding: 0, margin: 0 }} className="overflow-auto" id="body_msg">
                            {this.props.user.role == 'fiscal_coordenador' && this.state.aluno_select.id == 'comunicado' && this.props.comunicados.filter((item, id) => {
                                if (this.state.aluno_select.sala_id == undefined && item.sala_id == null) {
                                    return true;
                                }
                                else {
                                    if (this.state.aluno_select.sala_id == item.sala_id) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                            }).map((item, id) => (
                                <div key={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                    <div style={{ padding: '5px', backgroundColor: '#38a4f8', borderRadius: '5px', textAlign: 'center', display: 'flex', alignItems: 'flex-end' }} >
                                        <p style={{ marginBottom: 0, color: 'white', marginLeft: '1rem', marginRight: '1rem', textAlign: 'center', textAlign: 'center' }}>{item.message}</p>
                                        <div>
                                            <span style={{ fontSize: 10, marginRight: 2, color: 'white' }}>{moment(item.created_at).tz("America/Sao_Paulo").format('HH:mm')}</span>
                                            {item.send == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0, color: 'white' }}></i>}
                                            {item.send == true && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0, color: 'white' }}></i>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {this.state.aluno_select.id != 'comunicado' && this.state.aluno_select.mensagens.map((item, id) => {
                                if (item.warning == true) {
                                    return (
                                        <div key={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                            <div style={{ padding: '5px', backgroundColor: 'lightyellow', marginRight: '0.5rem', borderRadius: '5px', textAlign: 'center', display: 'flex', alignItems: 'flex-end' }} >
                                                <p style={{ marginBottom: 0, color: 'black', marginLeft: '1rem', marginRight: '1rem', textAlign: 'center', textAlign: 'center' }}>{item.message}<br /><span style={{ fontSize: 12 }}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</span></p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if (item.receive_id == this.state.aluno_select.id && item.sender_id == this.props.user.id) {
                                    return (
                                        <div key={id} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                            <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }} >
                                                <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem' }}>{item.message}</p>
                                                <div>
                                                    <span style={{ fontSize: 10, marginRight: 2 }}>{moment(item.created_at).tz("America/Sao_Paulo").format('HH:mm')}</span>
                                                    {item.visto == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                    {item.visto == false && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                    {item.visto == true && <i className="fas fa-check-double" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }
                                else if (item.sender_id == this.state.aluno_select.id) {
                                    return (
                                        <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                            <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender.name}</p>
                                                <p style={{ marginBottom: 0, color: 'black' }}>{item.message}</p>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, }}>{moment(item.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                            <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender.name}</p>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={{ marginBottom: 0, color: 'black' }}>{item.message}</p>
                                                    <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3, marginLeft: '0.5rem' }}>{moment(item.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>

                        {this.state.aluno_select.id != undefined && <div
                            style={{ height: '70px', backgroundColor: '#f2f2f2', borderTop: '1px solid lightgrey', padding: '5px', display: 'flex', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                            <input autoComplete="off" onKeyPress={(e) => {
                                let keyCode = e.keyCode || e.which;
                                if (keyCode == 13) {
                                    if (this.state.aluno_select.id != 'comunicado') {
                                        this.send_message();
                                    }
                                    else {
                                        this.send_comunicado();
                                    }
                                }
                            }} onChange={(e) => this.setState({ texto: e.target.value })} value={this.state.texto} name="msg" placeholder="Mensagem..." className="form-control msg_input" style={{ resize: 'none', height: '100%' }} />
                            <div className="send_msg" onClick={(e) => {
                                e.stopPropagation();

                                if (this.state.aluno_select.id != 'comunicado') {
                                    this.send_message();
                                }
                                else {
                                    this.send_comunicado();
                                }
                            }}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px' }}>
                                <i className="mdi mdi-send" style={{ fontSize: '1.4rem' }}></i>

                            </div>
                        </div>}
                    </div>
                </div>

            </div> : <div ref={this.messages_view} hidden></div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        faculdade: state.AppReducer.faculdade,
        chat_open: state.AppReducer.chat_open,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarMensagens })(Chat));


